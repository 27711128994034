import gql from 'graphql-tag';

export const CREATE_AUTHENTICATION = gql`
    mutation CreateAuthentication($identifier: String!, $password: String!) {
        createAuthentication(input: { identifier: $identifier, password: $password }) {
            id
            token
        }
    }
`;

export const DELETE_AUTHENTICATION = gql`
    mutation DeleteAuthentication($id: ID!) {
        deleteAuthentication(id: $id) {
            id
        }
    }
`;
